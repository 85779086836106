// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mapbox: {
    accessToken: 'pk.eyJ1IjoidGhvbWFzbW9vbiIsImEiOiJjanEwZm40ZGkwa2cxNDh0ZGh6MWMxcHlnIn0.bJ5Qq3Upnx93SfGzcbowKg'
  },
  firebase: {
    apiKey: "AIzaSyCSwbN-xjqeAnus5sk_iy71GsNLpDZ3IrY",
    authDomain: "eira-360.firebaseapp.com",
    databaseURL: "https://eira-360.firebaseio.com",
    projectId: "eira-360",
    storageBucket: "eira-360.appspot.com",
    messagingSenderId: "971018431465"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
